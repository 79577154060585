.select2 {
    max-width: 100%; }

.form-group {
    .clear-select2 {
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 10;
        color: grey;

        &.no-offset {
            right: 30px; } } }
