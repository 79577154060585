.alert {
    display: inline-block;
    max-width: 600px; }

.dropdown-item:not(.active):hover, .dropdown-item:not(.active):focus {
    background-color: #ececec; }

.card {
    .table-striped {
        th {
            border-top: none; } } }
