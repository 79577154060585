@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-Regular.woff2') format('woff2'), url('../fonts/Moderat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal; }

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-RegularItalic.woff2') format('woff2'), url('../fonts/Moderat-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic; }

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-Black.woff2') format('woff2'), url('../fonts/Moderat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal; }

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-Light.woff2') format('woff2'), url('../fonts/Moderat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal; }

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-LightItalic.woff2') format('woff2'), url('../fonts/Moderat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic; }

html {
    height: 100%; }
body {
    position: relative;
    min-height: 100%;
    width: 100%;
    background: #f3f3f3;
    font-family: $font-main;
    color: $color-primary;
    font-size: 16px;
    text-rendering: optimizeLegibility;

    #side-loader {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: rgba(0,0,0,.5);
        color: #fff;
        i {
            font-size: 4rem; } } }

h1, .h1 {
    hyphens: auto;
    word-wrap: break-word; }

