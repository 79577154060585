//////////////
//  Colors  //
//////////////
$color-primary: #2d2d2d;
$color-secondary: #fff;


/////////////
//  Fonts  //
/////////////
$font-main: 'Moderat', 'Helvetica', 'Arial', sans-serif;

$h1-font-size: 40px;
$h2-font-size: 30px;
$h3-font-size: 18px;


/////////////
//  Other  //
/////////////
$imagepath: "/assets/img/layout";
