section#login {
    padding-top: 50px;

    form.login-form {
        max-width: 330px;
        padding: 15px;
        margin: auto;

        .checkbox {
            font-weight: 400; }

        .form-control {
            position: relative;
            box-sizing: border-box;
            height: auto;
            padding: 10px;
            font-size: 16px;

            &:focus {
                z-index: 2; } }

        input[type="email"] {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0; }

        input[type="password"] {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0; } } }
