#content-wrapper #content {
    background-color: #f3f3f3; }

.navbar-wrapper {
    position: sticky;
    top: 0;
    z-index: 1000; }

#wrapper {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media (min-width: 1921px) {
        .sidebar {
            width: 100% !important;

            .nav-item .nav-link {
                width: 100% !important; } }

        .sidebar-wrapper {
            min-width: 14rem;

            #accordionSidebar {
                margin-left: auto; } }

        main {
            max-width: calc(1920px - 14rem);
            margin-right: auto;
            margin-left: initial; } } }

.bg-gradient-primary {
    background-color: $color-primary;
    //background-image: linear-gradient(180deg, $color-primary 10%, darken($color-primary, 15%) 100%)
    background-image: none;
    background-size: cover; }

.text-primary {
    color: $color-primary !important; }

.sidebar #sidebarToggle:after {
    font-family: 'FontAwesome' !important; }

.topbar .nav-item .nav-link .img-profile {
    display: inline-block;
    background: $color-primary;
    font-family: 'FontAwesome' !important;
    position: relative;
    color: #fff;

    &:before {
        content: "\f007";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
