textarea {
    resize: vertical;
    min-height: 37px; }

input#image {
    display: inline-block; }

.no-horizontal {
    .form-group {
        margin-left: auto;
        margin-right: auto; } }

.form-group {
    .compound > .form-group,
    > .form-group {
        margin: 0; }

    .col-form-label,
    label {
        &.required {
            font-weight: bold;
            > * {
                font-weight: normal; } } }

    input[type="file"] {
        padding: 0 0 5px;
        text-indent: -999px;
        cursor: pointer; }

    input[type="date"].form-control {
        line-height: initial; }

    .checkbox {
        display: inline-block;
        padding: 0 15px 0 0;
        min-height: 0; }

    .custom-file {
        overflow: hidden;

        .custom-file-label:after {
            content: '\f093';
            font-family: 'FontAwesome'; } }

    .file-download-link {
        font-weight: normal; }

    .compound-list li > div:first-child {
        flex-shrink: 0;
        flex-grow: 1; } }

.modal .form-group.note-form-group {
    margin: 0; }
